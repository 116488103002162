<template>
  <vx-table :resource="resource" :columns="columns" :filters="filters" />
</template>

<script>
import { VxCellButtons, VxCellDate, VxCellLink, VxCellPlayerAvatar, VxTable } from '@/components/table'
import { passDataToResource, withdrawals } from '@/services/resources'
import { filterTypes, sortOrders } from '@/services/table'
import { buildRoute, path } from '@/router'
import { buttons } from '@/components/table/vx-table-cells/vx-cell-button'

export default {
  name: 'Withdrawals',
  components: {
    VxTable,
    VxCellDate,
    VxCellLink,
    VxCellButtons,
    VxCellPlayerAvatar
  },
  setup () {
    const resource = passDataToResource(withdrawals.getAll, {
      requestParams: {
        params: { include: 'drop.player,purchase' }
      }
    })

    const columns = [
      {
        key: 'id',
        sortable: true,
        defaultSort: sortOrders.desc
      },
      {
        key: 'drop',
        component: VxCellLink,
        tdAttr: ({ id, item }) => ({
          label: item.title,
          subLabel: `#${id}`,
          href: buildRoute(path.drops, { query: { id } })
        })
      },
      {
        key: 'drop.player',
        label: 'Player',
        component: VxCellPlayerAvatar
      },
      { key: 'status' },
      { key: 'error_code' },
      {
        key: 'created_at',
        component: VxCellDate,
        sortable: true
      },
      {
        key: 'updated_at',
        component: VxCellDate,
        sortable: true
      },
      {
        key: 'actions',
        component: VxCellButtons,
        tdAttr: (value, key, item) => ({
          buttons: [
            {
              ...buttons.view,
              to: buildRoute(path.withdrawal, { params: { id: item.id } })
            }
          ]
        })
      }
    ]

    const filters = [
      {
        key: 'id',
        type: filterTypes.number
      },
      {
        key: 'player_id',
        type: filterTypes.number
      },
      {
        key: 'drop_id',
        type: filterTypes.number
      },
      {
        key: 'status',
        type: filterTypes.serverSelect,
        resourceMapper: ({ statuses }) => statuses,
        resource: withdrawals.statuses,
        searchable: false
      },
      {
        key: 'error_code',
        type: filterTypes.text
      },
      {
        key: 'created_at',
        type: filterTypes.dateRangePicker,
        apiKeys: ['from_created_at', 'to_created_at']
      },
      {
        key: 'updated_at',
        type: filterTypes.dateRangePicker,
        apiKeys: ['from_updated_at', 'to_updated_at']
      }
    ]

    return {
      resource,
      columns,
      filters
    }
  }
}
</script>
